<template>
  <div>
    <el-row class="noticeTlt">
      <el-col :span="16">
        <div style="color:#61688a;font-size: 14px; line-height:65px">
          <span style="color:#61688a;font-size: 18px;cursor:pointer;" @click="gotoIndex">
            {{ $t('message.Home') }}
          </span>
          >
          <span style="color:#61688a;font-size: 18px;">{{ title }}</span>
        </div>
      </el-col>
    </el-row>
    <el-card class="box-card">
      <el-row>
        <el-col :span="22" :offset="1">
          <el-row>
            <el-col :span="24">
              <div style="font-size:32px;margin-bottom:20px;margin-top:10px">{{ title }}</div>
            </el-col>
            <el-col :span="24">
              <!-- <img v-show="time" src="../../../assets/images/noticeLogo.png" style="height:45px"> -->
              <span style="color:#61688a;margin-left:5px;">{{ time }}</span>
            </el-col>
          </el-row>
          <div style="min-height:500px;margin-top:20px">
            <div class="cardContent" v-html="content" />
          </div>
          <div style="text-align:center">
            <img :src="imgUrl" alt="" style="width:90%;height:auto;">
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
/*global http protocol*/
export default {
  name: 'NoticeDetail',
  data() {
    return {
      currentclassifyId: 2,
      // isCurrentId: this.$route.params.articleId,
      content: '',
      sign: '',
      title: '',
      time: '',
      imgUrl: '',

      ismore: this.$t('message.is_more'),
      selectnPage: window.sessionStorage.getItem('selectnPage'),
      param_getArticle: {}
    }
  },
  computed: {
    isCurrentId() {
      return this.$route.params.articleId
    }
  },
  watch: {
    isCurrentId() {
      this.getArticle(this.isCurrentId)
    }
  },
  mounted() {
    // const currentTab = window.sessionStorage.getItem('selectnPage')
    this.getArticle(this.isCurrentId)
  },
  updated() {
    this.getArticle(this.isCurrentId)
  },
  methods: {
    gotoNotice() {
      this.$router.push('/notice')
    },

    gotoIndex() {
      this.$router.push('/index')
    },

    getArticle(id) {
      const that = this
      // const localeLanguage = localStorage.lang || navigator.language.slice(-2).toLowerCase()
      this.param_getArticle = protocol.param_getContentDetails
      this.param_getArticle.param.id = id
      http.getRes(this.param_getArticle).then(response => {
        const { code, message, value } = response.data
        if (code == '0') {
          that.imgUrl = value.titleImg
          that.title = value.title
          that.content = value.content
          that.time = global.util.dateFormate(Number(value.createTime))
        } else {
          this.$EL_MESSAGE(message)
        }
      })
    }

  }
}
</script>
<style scoped lang="scss">
   .noticeTlt{
    margin-top: 29px;
    height: 65px;
    background-color: transparent;
    line-height: 65px;
    font-family: 'MicrosoftYaHei', '微软雅黑';
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #FFFFFF;
    padding-left:20px;
  }
  .noticeTlt /deep/ .el-input__inner{
    background-color:transparent;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    overflow: hidden;
    // border: 1px solid rgba(64,144,253,1);
    border: none;
    background: #263562;
    color: #B0B8DB;
  }
  .noticeTlt /deep/ .el-input__inner::-webkit-input-placeholder { /* WebKit browsers */
    color: #B0B8DB;
    font-size: 14px;
  }

  .noticeTlt /deep/ .el-input__inner::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #B0B8DB;
    font-size: 14px;
  }

  .noticeTlt /deep/ .el-input__inner:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #B0B8DB;
    font-size: 14px;
  }
  .currentId{
    color: #2483ff;
  }
  .titleClass{
    line-height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size:14px;
    cursor: pointer;
  }
  .more{
    color:#5F82F9;
    cursor: pointer;
    margin-top: 10px;
  }
  .cardContent{
    /deep/ p{
      margin: 10px 0 !important;
      font-size: 16px;
    }
  }
  /deep/ .box-card{
    background-color: #263562;
    margin-bottom: 50px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 1200px;
  }
  /deep/ .el-card__body{
    padding: 30px 60px;
  }
</style>
